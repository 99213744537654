<template>
  <div class="container">
    <div class="page-title">
      <el-row>
        <el-col :span="18">
          <h3>管理者</h3>
        </el-col>
        <el-col :span="6" style="text-align: right;">

        </el-col>
      </el-row>
    </div>
    <div class="wrap">
      <div class="page-sider">
        <h4><span @click="addManagerPop=true"><i class="el-icon-plus"></i></span>管理者</h4>
        <el-menu :default-active="activeManagerId" class="menu">
          <el-menu-item :index="(index+1).toString()" class="menu-li" style="padding-left: 0" v-for="(item,index) in managerList" :key="item.id" @click="getManagerDetail(item.id)">
            <template slot="title">
                            <span>
                                <i class="el-icon-edit-outline" @click="editManager(item,index)"></i>
                                <i class="el-icon-delete" @click="deleteManager(item.id, index)"></i>
                            </span>
              <i class="el-icon-user"></i>{{item.name}}
            </template>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="page-content">
        <el-card class="box-card" shadow="never">
          <el-row>
            <el-col :span="8">姓名：{{manager.name}}</el-col>
            <el-col :span="8">电话：{{manager.phone}}</el-col>
            <el-col :span="8">备注：{{manager.note}}</el-col>
          </el-row>
        </el-card>
        <div class="tab-wrap">
          <el-form :inline="true" :model="searchForm" @submit.native.prevent class="search-form">
            <el-input placeholder="请输入关键字" size="small" prefix-icon="el-icon-search" v-model="searchForm.keys" style="width: 240px">
              <el-button slot="append" icon="el-icon-search" native-type="submit" @click="search"></el-button>
            </el-input>
          </el-form>
        </div>

        <el-tabs v-model="activeName" type="card" @tab-click="switchTab">
          <el-tab-pane label="管理的主机" name="host"></el-tab-pane>
          <el-tab-pane label="管理的安全设备" name="safe"></el-tab-pane>
          <el-tab-pane label="管理的网络设备" name="network"></el-tab-pane>
          <el-tab-pane label="管理的其他设备" name="other"></el-tab-pane>
          <el-tab-pane label="管理的应用" name="app"></el-tab-pane>
          <!--                    <el-tab-pane label="管理的软件" name="soft"></el-tab-pane>-->
          <el-tab-pane label="管理的集群" name="group"></el-tab-pane>
        </el-tabs>

        <!--主机-->
        <div class="tab-box" v-if="activeName=='host'">
          <el-table :data="tableHostData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="主机名称" prop="name">
            </el-table-column>
            <el-table-column label="类型" prop="type">
              <template slot-scope="scope">
                <span v-if="scope.row.type==5">物理机</span>
                <span v-else-if="scope.row.type==6">虚拟机</span>
                <span v-else>其他</span>
              </template>
            </el-table-column>
            <el-table-column label="位置">
              <template slot-scope="scope">
                {{scope.row.room}} {{scope.row.cab}}
              </template>
            </el-table-column>
            <el-table-column label="IP/MAC">
              <template slot-scope="scope">
                <p>{{scope.row.ip}}</p>
                <p>{{scope.row.mac}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/device/detail?id='+scope.row.id">详情</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
                    @current-change="changeHostPage"
                    :current-page="host.page"
                    :page-size="host.limit"
                    layout="total, prev, pager, next"
                    :total="hostTotal">
            </el-pagination>
          </div>
        </div>
        <!--应用-->
        <div class="tab-box" v-if="activeName=='app'">
          <el-table :data="tableAppData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="应用名称" prop="name">
            </el-table-column>
            <el-table-column label="备注" prop="note">
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/app?id='+scope.row.id" >详情</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
                    @current-change="changeAppPage"
                    :current-page="app.page"
                    :page-size="app.limit"
                    layout="total, prev, pager, next"
                    :total="appTotal">
            </el-pagination>
          </div>
        </div>
        <!--集群-->
        <div class="tab-box" v-if="activeName=='group'" >
          <el-table :data="tableGroupData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="集群名称" prop="name">
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                {{scope.row.note}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/group?id='+scope.row.id">详情</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
                    @current-change="changeGroupPage"
                    :current-page="group.page"
                    :page-size="group.limit"
                    layout="total, prev, pager, next"
                    :total="groupTotal">
            </el-pagination>
          </div>
        </div>
        <!--安全设备-->
        <div class="tab-box" v-if="activeName=='safe'">
          <el-table :data="tableSafeData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="设备名称" prop="name">
            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
                {{scope.row.type}}
              </template>
            </el-table-column>
            <el-table-column label="位置">
              <template slot-scope="scope">
                {{scope.row.room}} {{scope.row.cab}}
              </template>
            </el-table-column>
            <el-table-column label="IP/MAC">
              <template slot-scope="scope">
                <p>{{scope.row.ip}}</p>
                <p>{{scope.row.mac}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/safe/detail?id='+scope.row.id" >详情</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
                    @current-change="changeSafePage"
                    :current-page="safe.page"
                    :page-size="safe.limit"
                    layout="total, prev, pager, next"
                    :total="safeTotal">
            </el-pagination>
          </div>
        </div>
        <!--网络设备-->
        <div class="tab-box" v-if="activeName=='network'">
          <el-table :data="tableNetworkData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="设备名称" prop="name">
            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
                {{scope.row.sname}}
              </template>
            </el-table-column>
            <el-table-column label="位置">
              <template slot-scope="scope">
                {{scope.row.roomname}}<br/> {{scope.row.cabname}}
              </template>
            </el-table-column>
            <el-table-column label="IP/MAC">
              <template slot-scope="scope">
                <p>{{scope.row.ip}}</p>
                <p>{{scope.row.mac}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/network/detail?id='+scope.row.id" >详情</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
                    @current-change="changeNetworkPage"
                    :current-page="network.page"
                    :page-size="network.limit"
                    layout="total, prev, pager, next"
                    :total="networkTotal">
            </el-pagination>
          </div>
        </div>
        <!--其他设备-->
        <div class="tab-box" v-if="activeName=='other'">
          <el-table :data="tableOtherData" style="width: 100%" v-loading="loading" element-loading-text="加载中...">
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="设备名称" prop="name">
            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
                {{scope.row.type}}
              </template>
            </el-table-column>
            <el-table-column label="位置">
              <template slot-scope="scope">
                {{scope.row.room}} {{scope.row.cab}}
              </template>
            </el-table-column>
            <el-table-column label="IP/MAC">
              <template slot-scope="scope">
                <p>{{scope.row.ip}}</p>
                <p>{{scope.row.mac}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span class="row-opt"><el-link type="primary" icon="el-icon-document" :href="'/#/other/detail?id='+scope.row.id" >详情</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
                    @current-change="changeOtherPage"
                    :current-page="other.page"
                    :page-size="other.limit"
                    layout="total, prev, pager, next"
                    :total="otherTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="popManagerTitle" :visible.sync="addManagerPop" :close-on-click-modal="false" @close="closePop">
      <el-form :model="managerForm" label-width="140px" @submit.prevent="submitManager" :close-on-click-modal="false">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="managerForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="managerForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input v-model="managerForm.note" type="textarea" :rows="3"></el-input>
        </el-form-item>
        <div style="margin-top:30px; text-align: center">
          <el-button type="primary" native-type="submit" @click.prevent="submitManager">提交</el-button>
          <el-button @click="closePop">关闭</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: "Manager",
    data() {
      return {
        activeManagerId: '1',
        selManagerId: null,
        managerList: [],
        manager: {},
        addManagerPop: false,
        popManagerTitle: '新增管理者',
        managerForm: {},

        activeName: 'host',
        tableHostData: [],
        host: {
          page: 1,
          limit: 10,
        },
        hostTotal: 0,

        tableGroupData: [],
        group: {
          keys: '',
          page: 1,
          limit: 10,
        },
        groupTotal: 0,

        tableAppData: [],
        app: {
          page: 1,
          limit: 10,
        },
        appTotal: 0,

        tableSafeData: [],
        safe: {
          page: 1,
          limit: 10,
        },
        safeTotal: 0,
        tableNetworkData: [],
        network: {
          page: 1,
          limit: 10,
        },
        networkTotal: 0,
        tableOtherData: [],
        other: {
          page: 1,
          limit: 10,
        },
        otherTotal: 0,

        searchForm: {
          keys: ''
        },
        loading: true,
      }
    },
    created() {
      this.getManagerList();
    },
    methods: {
      getManagerList () {
        this.$get(this.$api.GetManagerList).then((res) => {
          this.managerList = res.data.list;
          this.selManagerId = this.managerList[0].id.toString();
          this.getManagerDetail(this.selManagerId);
        });
      },
      editManager(item) {
        this.$get(this.$api.GetManagerInfo, {id: item.id}).then((res) => {
          this.addManagerPop = true;
          this.popManagerTitle = '修改管理者';
          this.managerForm = res.data;
        });
      },
      submitManager() {
        if (this.managerForm.name == '') {
          this.$message.warning('姓名不能为空');
          return false;
        }
        let postUrl = this.$api.AddManager;
        if (this.managerForm.id) {
          postUrl = this.$api.EditManager;
        }
        this.$post(postUrl, this.managerForm).then(() => {
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.getManagerList();
          this.addManagerPop = false;
        });
      },
      deleteManager(id,index) {
        this.$confirm('此操作将删除所选项, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteManager, {id: id}).then(() => {
            this.$message({
              message: '操作成功！',
              type: 'success'
            });

            if (index === 0) {
              this.getManagerList();
            } else {
              this.managerList.splice(index, 1);
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      getManagerDetail(id) {
        this.selManagerId = id;
        this.activeName = 'host';
        this.loading = true;
        this.getHostList();
        this.$get(this.$api.GetManagerInfo, {id: id}).then((res) => {
          this.manager = res.data;
        })
      },

      switchTab(tab) {
        this.searchForm.keys = '';
        this.loading = true;
        if (tab.name == 'host') {
          this.tableHostData = [];
          this.getHostList();
        } else if (tab.name == 'app') {
          this.tableAppData = [];
          this.getAppList();
        } else if (tab.name == 'group') {
          this.tableGroupData = [];
          this.getGroupList();
        } else if (tab.name == 'safe') { //安全设备列表
          this.tableSafeData = [];
          this.getSafeList();
        } else if (tab.name == 'network') { //网络设备列表
          this.tableNetworkData = [];
          this.getNetworkList();
        } else { //其他设备列表
          this.tableOtherData = [];
          this.getOtherList();
        }
      },

      searchHost() {
        this.loading = true;
        this.host.page = 1;
        this.tableHostData = [];
        this.hostTotal = 0;
        this.getHostList();
      },
      getHostList () {
        this.host.manager_id = this.selManagerId;
        this.host.keys = this.searchForm.keys;
        this.host.type = 1;
        this.$get(this.$api.GetDeviceList, this.host).then((res) => {
          this.loading = false;
          this.tableHostData = res.data.list;
          this.hostTotal = res.data.total_count;
          if (this.hostTotal > this.host.limit) {
            this.showHostPage = true;
          }
        });
      },
      changeHostPage (val) {
        this.host.page = val
        this.getHostList()
      },
      searchApp() {
        this.loading = true;
        this.app.page = 1;
        this.tableAppData = [];
        this.appTotal = 0;
        this.getAppList();
      },

      getAppList() {
        this.app.manager_id = this.selManagerId;
        this.app.keys = this.searchForm.keys;
        this.$get(this.$api.GetAppList, this.app).then((res) => {
          this.loading = false;
          this.tableAppData = res.data.list;
          this.appTotal = res.data.total_count;
          if (res.data.total_page > 1) {
            this.showAppPage = true;
          }
        });
      },
      changeAppPage(val) {
        this.app.page = val;
        this.getAppList();
      },


      getGroupList() {
        this.group.manager_id = this.selManagerId;
        this.group.keys = this.searchForm.keys;
        this.$get(this.$api.GetGroupList, this.group).then((res) => {
          this.loading = false;
          this.tableGroupData = res.data.list;
          this.groupTotal = res.data.total_count;
        });
      },
      changeGroupPage(val) {
        this.group.page = val;
        this.getGroupList();
      },

      closePop() {
        this.addManagerPop = false;
        this.managerForm = {};
        this.popManagerTitle = '新增管理者';
      },

      getSafeList() {
        this.safe.manager_id = this.selManagerId;
        this.safe.keys = this.searchForm.keys;
        this.safe.type = 2;
        this.$get(this.$api.GetDeviceList, this.safe).then((res) => {
          this.loading = false;
          this.tableSafeData = res.data.list;
          this.safeTotal = res.data.total_count;
        });
      },
      changeSafePage(val) {
        this.safe.page = val;
        this.getSafeList();
      },
      getNetworkList() {
        this.network.manager_id = this.selManagerId;
        this.network.keys = this.searchForm.keys;
        this.network.type = 18;
        this.$get(this.$api.GetDeviceList, this.network).then((res) => {
          this.loading = false;
          if (res.code === 0) {
            this.tableNetworkData = res.data.list;
            this.networkTotal = res.data.total_count;
          } else {
            this.$message.error(res.message)
          }
        });
      },
      changeNetworkPage(val) {
        this.network.page = val;
        this.getNetworkList();
      },
      getOtherList() {
        this.other.manager_id = this.selManagerId;
        this.other.keys = this.searchForm.keys;
        this.other.type = 3;
        this.$get(this.$api.GetDeviceList, this.other).then((res) => {
          this.loading = false;
          this.tableOtherData = res.data.list;
          this.otherTotal = res.data.total_count;
        });
      },
      changeOtherPage(val) {
        this.other.page = val;
        this.getOtherList();
      },

      //搜索
      search() {
        this.loading = true;
        if (this.activeName == 'host') {
          this.getHostList();
        } else if (this.activeName == 'app') {
          this.getAppList();
        } else if (this.activeName == 'group') {
          this.getGroupList();
        } else if (this.activeName == 'safe') {//安全设备列表
          this.getSafeList();
        } else if (this.activeName == 'network') {//网络设备列表
          this.getNetworkList();
        } else { //其他设备列表
          this.getOtherList();
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .query-box {
    float: right;
    width: 300px;
    background: #f6f6f6;
    padding: 10px;
    margin: 10px 0;
  }
  .pagenation{
    margin: 20px auto;
    text-align: center;
  }
  .page-sider{
    width: 160px;
    height: 100%;
    position: absolute;
    border-right: 1px solid #e6e6e6;
    h4{
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #d3d3d3;
      margin-right: 6px;
      span{
        float: right;
        margin-right: 6px;
        cursor: pointer;
        i{
        }
      }
    }
    .menu{
      span{
        float: right;
        display: none;
      }
      .menu-li:hover{
        span{
          display: block;
        }
      }
    }
  }
  .page-content{
    position: absolute;
    left: 200px;
    right: 0;
    top: 50px;
    bottom:0;
    width: auto;
    padding:0;
    box-sizing: border-box;
  }
  /deep/.el-menu-item{
    height: 40px;
    line-height: 40px;
    i{
      font-size: 13px;
      color: #999;
    }
  }
  /deep/.el-menu-item [class^=el-icon-]{
    width: 20px;
    margin-right: 0;
  }
  .box-card{
    margin-right: 10px;
    margin-bottom: 10px;
    .search-form{
      float: right;
      width: 250px;
      margin-top: -5px;
    }
  }

  .tab-wrap{
    position: relative;
    .search-form{
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999;
    }
  }
</style>
